import instance from '.'
import { err, getResult } from '../utils/functions'
import { APIResult } from '../utils/types'
import { Client } from './types'

export async function createAccount(
  email: string,
  hashPass: string,
  hashCheckPass: string,
): Promise<APIResult<string>> {
  return instance
    .post('/api/account/createAccount', {
      email,
      password: hashPass,
      checkPassword: hashCheckPass,
    })
    .catch((e) => err(e))
    .then((res) => getResult<string>(res))
}

export async function login(
  email: string,
  hashPass: string,
): Promise<APIResult<string>> {
  return instance
    .post('/api/account/login', {
      email,
      password: hashPass,
    })
    .catch((e) => err(e))
    .then((res) => getResult<string>(res))
}

export async function logout(): Promise<APIResult<string>> {
  return instance
    .post('/api/account/logout')
    .catch((e) => err(e))
    .then((res) => getResult<string>(res))
}

export async function clientLogin(
  clientId: number,
): Promise<APIResult<string>> {
  return instance
    .post('/api/account/clientLogin', {
      clientLoginId: clientId,
    })
    .catch((e) => err(e))
    .then((res) => getResult<string>(res))
}

export async function getUserClients(): Promise<APIResult<Client[]>> {
  return instance
    .get('/api/account/getUserClients')
    .catch((e) => err(e))
    .then((res) => getResult<Client[]>(res))
}

export async function createClient(
  name: string,
  displayName: string,
  description?: string,
): Promise<APIResult<Client>> {
  return await instance
    .post('/api/account/createClient', {
      name,
      displayName,
      description,
    })
    .then((res) => getResult<Client>(res))
}
