import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Component from '../common/Component'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { createService } from '../../api/admin'
import { getAvailableServices } from '../../api/service'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Alert, { AlertInput } from '../common/Alert'
import { err } from '../../utils/functions'

export default function CreateService(): JSX.Element {
  const [services, setServices] = useState<string[]>([])
  const [name, setName] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [description, setDescription] = useState('')
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  useEffect(() => {
    getAvailableServices()
      .then((res) => {
        if (res.success && res.data) {
          setServices(res.data)
        }
      })
      .catch((e) => err(e))
  }, [])

  const handleSubmit = async (): Promise<void> => {
    const result = await createService(name, displayName, description)

    if (result.success) {
      setAlert({
        open: true,
        severity: 'success',
        message: 'Successfully created service.',
      })
      clearForm()
    } else {
      setAlert({
        open: true,
        severity: 'error',
        message: result.message,
      })
    }
    // TODO add snackbar alerting
  }

  const clearForm = () => {
    setName('')
    setDisplayName('')
    setDescription('')
  }

  return (
    <>
      <Component title="Create Service">
        <Grid container spacing={2} justifyContent="center" direction="column">
          <Grid item>
            <Typography>
              Create a Service. This is will officially post the service to be
              available for all clients - visable in the &apos;Add Service&apos;
              feature. Users will be able to add the service to the dashboard,
              select the invoice method, configure the <strong>fee</strong>, and
              run the service.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel
                id="available-services-select-label"
                style={{ marginLeft: '1em' }}
              >
                Available Services
              </InputLabel>
              <Select
                id="available-services-select"
                labelId="available-services-select-label"
                value={name}
                onChange={(e) => setName(e.target.value as string)}
                style={{ width: '250px' }}
              >
                <MenuItem value={''}>
                  <em>None</em>
                </MenuItem>
                {services.map((service, i) => (
                  <MenuItem key={`available-services-${i}`} value={service}>
                    {service}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Display name"
              required
              size="small"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Description"
              rows={4}
              multiline
              style={{ minWidth: '0px' }}
              value={description}
              size="small"
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Component>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </>
  )
}
