import React, { useContext } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import Login from '../pages/account/Login'
import CreateAccount from '../pages/account/CreateAccount'
import ForgotPassword from '../pages/account/ForgotPassword'
import Service from '../pages/Service'
import Admin from '../pages/admin/Admin'
import AppContext from './AppContext'
import SelectClient from '../pages/account/SelectClient'
import Clients from '../pages/admin/Clients'
import { MS_TO_SEC } from '../utils/constants'
import AboutPage from '../pages/AboutPage'
import SupportPage from '../pages/SupportPage'

export default function AppRouter(): JSX.Element {
  const LOGIN_PATH = '/login'
  const redirectPath: string | undefined =
    window.location.pathname + window.location.search
  // Do not add redirect path if it already exists or if already on login path
  const loginRedirect =
    window.location.pathname !== LOGIN_PATH && // not already on login page
    redirectPath && // redirect path exists
    redirectPath !== '/' && // redirect path is not root path
    !redirectPath.includes('redirectTo=') // not already redirectingTo
      ? LOGIN_PATH + `?redirectTo=` + redirectPath
      : LOGIN_PATH + window.location.search

  const { appState } = useContext(AppContext)
  const isAuthenticated = appState.user.id
  const isNotLoggedIntoClient =
    appState.user.id && !appState.client.id && appState.user.clientIds
  const jwtExpired = (appState.jwt.exp || 0) < new Date().getTime() * MS_TO_SEC

  return (
    <Router>
      {!isAuthenticated ? (
        <Redirect to={{ pathname: loginRedirect, state: {} }}></Redirect>
      ) : null}

      {jwtExpired ? (
        <Redirect to={{ pathname: loginRedirect, state: {} }}></Redirect>
      ) : null}

      {isNotLoggedIntoClient ? (
        <Redirect
          to={{
            pathname: '/selectClient',
            state: {},
          }}
        ></Redirect>
      ) : null}

      <div className="App">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <Switch>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/support">
            <SupportPage />
          </Route>
          <Route path="/login(:?\?redirectTo=.*)?">
            <Login />
          </Route>
          <Route path="/selectClient">
            <SelectClient />
          </Route>
          <Route path="/createAccount">
            <CreateAccount />
          </Route>
          <Route path="/forgotPassword">
            <ForgotPassword />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/admin/:clientId?">
            <Admin />
          </Route>
          <Route path="/clients/">
            <Clients />
          </Route>
          <Route path="/service/:serviceId" component={Service}></Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}
