import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Component from '../common/Component'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { updateClient } from '../../api/admin'
import { Client } from '../../api/types'
import { log } from '../../utils/functions'

interface UpdateClientProps {
  client: Client
}

export default function UpdateClient({
  client,
}: UpdateClientProps): JSX.Element {
  const [displayName, setDisplayName] = useState(client.displayName)
  const [description, setDescription] = useState(client.description)

  const handleSubmit = async (): Promise<void> => {
    await updateClient(client.id, displayName, description).then((res) =>
      log(res),
    )
    // TODO add snackbar alerting
  }
  return (
    <Component title="Update Client">
      <Grid container spacing={2} justifyContent="center" direction="column">
        <Grid item>
          <Typography>Update Client details</Typography>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            label="Display Name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            label="Description"
            value={description}
            multiline
            rows={4}
            fullWidth
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Component>
  )
}
