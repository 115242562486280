import React, { useContext, useEffect, useState, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import NavBar from '../../components/common/NavBar'
import ClientSelect from '../../components/admin/ClientSelect'
import CreateClient from '../../components/admin/CreateClient'
import AddUser from '../../components/admin/AddUserRole'
import UpdateClient from '../../components/admin/UpdateClient'
import { getClient } from '../../api/admin'
import CreateService from '../../components/admin/CreateService'
import Container from '@mui/material/Container'
import AllClients from '../../components/admin/AllClients'
import AddService from '../../components/admin/AddService'
import { err, isAdmin, isSuperAdmin } from '../../utils/functions'
import AppContext from '../../app/AppContext'
import { Client } from '../../api/types'
import { Box } from '@mui/system'

export default function Admin(): JSX.Element {
  const { appState } = useContext(AppContext)
  const isMounted = useRef(true)
  const roleId = appState.user.roleId
  const clientId = appState.client.id
  const [client, setClient] = useState<Client>()
  useEffect(() => {
    if (isMounted.current) {
      if (clientId) {
        getClient({ id: clientId })
          .then((res) => {
            if (res?.data) {
              setClient(res.data)
            }
          })
          .catch((e) => err(e))
      }
      return () => {
        isMounted.current = false
      }
    }
  }, [clientId])

  if (!clientId) {
    return <div></div>
  }

  // TODO snackbar alert handling
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{
            padding: '1em',
          }}
        >
          {isSuperAdmin(roleId) && (
            <>
              <Grid item xs={12} md={12} sx={{ marginTop: '2em' }}>
                <Typography variant="h4">Super Admin Features</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <AllClients />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <ClientSelect />
                  </Grid>

                  <Grid item xs={12}>
                    <CreateClient />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {client && isAdmin(roleId) ? (
            <>
              <Grid item xs={12} md={12} sx={{ marginTop: '2em' }}>
                <Typography variant="h4">{client.displayName}</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <AddUser clientId={clientId} />
              </Grid>
              <Grid item xs={12} md={6}>
                <UpdateClient client={client} />
              </Grid>
              <Grid item xs={12} md={6}>
                <AddService clientId={clientId} />
              </Grid>
            </>
          ) : (
            <Grid>
              <Typography>
                You do not have sufficient permissions to access these features.
              </Typography>
            </Grid>
          )}

          {isAdmin(roleId) && (
            <>
              <Grid item xs={12} sx={{ marginTop: '2em' }}>
                <Typography variant="h4">Admin Features</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <CreateService />
              </Grid>

              <Grid item xs={12}>
                <Box p={40} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  )
}
