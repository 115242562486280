import React, { useEffect, useContext, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Component from '../components/common/Component'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useHistory } from 'react-router-dom'
import { getClientServices, GetLog, getLogs } from '../api/service'
import { getClient } from '../api/admin'
import AppContext from '../app/AppContext'
import Alert, { AlertInput } from '../components/common/Alert'
import CreateClient from '../components/admin/CreateClient'
import ServiceLogTable from '../components/service/ServiceLogTable'
import { Client, GetClientService } from '../api/types'
import { err, log } from '../utils/functions'
import NavBar from '../components/common/NavBar'

export default function Dashboard(): JSX.Element {
  const history = useHistory()
  const isMounted = useRef(true)
  const { appState } = useContext(AppContext)
  const clientId = appState.client?.id

  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [logs, setLogs] = useState<GetLog[]>()

  const [services, setServices] = useState<GetClientService[]>([])
  const [client, setClient] = useState<Client>()
  void client
  /* Get Client */
  useEffect(() => {
    if (isMounted.current) {
      getClient({ id: clientId })
        .then((res) => setClient(res?.data))
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  })

  /* Get Services */
  useEffect(() => {
    if (isMounted.current && clientId) {
      getClientServices(clientId)
        .then((res) => setServices(res?.data || []))
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  })

  /* Get Service Logs */
  useEffect(() => {
    if (isMounted.current && clientId) {
      getLogs(clientId, { paid: false })
        .then((res) => setLogs(res?.data || []))
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  })

  const handleServiceClick = async (
    serviceId: number | undefined,
  ): Promise<void> => {
    if (serviceId !== undefined) {
      history.push(`/service/${serviceId}`)
    } else {
      log('Error, invalid service')
    }
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container>
        {clientId ? (
          <Grid
            container
            justifyContent="center"
            spacing={3}
            sx={{
              mt: 1,
            }}
          >
            <Grid item xs={12} md={5} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Component title="Services">
                    <Grid container spacing={2}>
                      {services.length ? (
                        services?.map((service, i) => (
                          <ServiceItem
                            name={service.displayName || service.name}
                            onClick={() => handleServiceClick(service.id)}
                            key={`service-item-${i}`}
                          />
                        ))
                      ) : (
                        <Grid item xs={12}>
                          No Services
                        </Grid>
                      )}
                    </Grid>
                  </Component>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ServiceLogTable logs={logs} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={7}></Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{
              padding: '1em',
            }}
          >
            <Grid item xs={12}>
              <Component>
                <Typography>
                  <strong>Note: </strong>It appears you don&apos;t belong to a
                  client.
                </Typography>
                <Typography>
                  If you need to be added to an existing client, contact your
                  manage or a sales representative at info@teamautomate.com
                </Typography>
                <Typography>
                  If you are a busniess owner, please create a client below.
                </Typography>
              </Component>
            </Grid>
            <Grid item xs={12} md={6}>
              <CreateClient />
            </Grid>
          </Grid>
        )}
      </Container>

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}

function ServiceItem(props: {
  name: string
  onClick: () => void
}): JSX.Element {
  return (
    <Grid item xs={12}>
      <Button
        variant="outlined"
        fullWidth
        sx={{
          cursor: 'pointer',
          backgroundColor: '#fafafa',
        }}
        onClick={props.onClick}
      >
        <Box p={0.5}>
          <Typography variant="button">{props.name}</Typography>
        </Box>
      </Button>
    </Grid>
  )
}
