import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { useHistory } from 'react-router-dom'
import AppContext from '../../app/AppContext'
import { logout } from '../../api/account'
import Cookies from 'js-cookie'

const pages: string[] = []
const settings = ['Client', 'Dashboard', 'Logout']

export default function NavBar(): JSX.Element {
  const history = useHistory()
  const { appReducer } = React.useContext(AppContext)
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  )

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleNavClick = (name: string) => {
    switch (name) {
      case 'Account':
        history.push('/account')
        break
      case 'Client':
        history.push('/admin')
        break
      case 'Dashboard':
        history.push('/dashboard')
        break
      case 'Logout':
        void handleLogout()
        break
      default:
        break
    }
  }

  const handleLogout = async () => {
    const result = await logout()
    if (result.success) {
      appReducer({ type: 'jwt', payload: undefined })
      Cookies.remove('jwt', { path: '' })
      history.push('/login')
    }
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar position="static" sx={{ height: '50px' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters variant="dense" sx={{ height: '50px' }}>
          <IconButton
            edge="start"
            // className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
          >
            <div
              style={{
                width: 35,
                height: 35,
                borderRadius: 35,
                overflow: 'hidden',
                alignContent: 'center',
                display: 'flex',
                position: 'relative',
              }}
            >
              <img
                src="https://i.pinimg.com/originals/0c/67/5a/0c675a8e1061478d2b7b21b330093444.gif"
                height={35}
                width={48}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '50%',
                  transform: 'translate(50%,-50%)',
                }}
                alt={''}
              ></img>
            </div>
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 1,
              display: { xs: 'none', md: 'flex' },
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            たあ ・ Team Automate
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => handleNavClick(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <IconButton
            edge="start"
            // className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'flex', md: 'none' }, mr: 0 }}
          >
            <div
              style={{
                width: 35,
                height: 35,
                borderRadius: 35,
                overflow: 'hidden',
                alignContent: 'center',
                display: 'flex',
                position: 'relative',
              }}
            >
              <img
                src="https://i.pinimg.com/originals/0c/67/5a/0c675a8e1061478d2b7b21b330093444.gif"
                height={35}
                width={48}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '50%',
                  transform: 'translate(50%,-50%)',
                }}
                alt={''}
              ></img>
            </div>
          </IconButton>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 500,
              fontSize: 18,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Team Automate
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => handleNavClick(page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="" sx={{ height: 35, width: 35 }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={() => {
                handleCloseUserMenu()
              }}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => {
                    handleNavClick(setting)
                    handleCloseUserMenu()
                  }}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
