import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Component from '../common/Component'
import TextField from '@mui/material/TextField'
import { formatNameValue } from '../../utils/functions'
import Alert, { AlertInput } from '../common/Alert'
import { clientLogin, createClient } from '../../api/account'
import AppContext, { JWT } from '../../app/AppContext'
import jwt from 'jsonwebtoken'

export default function CreateClient(): JSX.Element {
  const { appReducer } = useContext(AppContext)
  const history = useHistory()
  const [name, setName] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [description, setDescription] = useState('')
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleNameChange = (name: string): void => {
    setName(formatNameValue(name))
  }

  const clearForm = () => {
    setName('')
    setDisplayName('')
    setDescription('')
  }

  const handleSubmit = async (): Promise<void> => {
    if (name && displayName) {
      const result = await createClient(name, displayName, description)

      if (result.success) {
        clearForm()
        const clientId = result.data?.id
        if (!clientId) {
          setAlert({
            open: true,
            severity: 'error',
            message: 'Could not find client. Try again.',
          })
          return
        }
        const res = await clientLogin(clientId)
        if (result.success) {
          const jwtData = jwt.decode(res.data || '') as JWT
          appReducer({ type: 'jwt', payload: jwtData })
          history.push('/dashboard')
        } else {
          setAlert({
            open: true,
            severity: 'error',
            message: res.message,
          })
        }

        // re log user into client
      } else {
        setAlert({ open: true, severity: 'error', message: result.message })
      }
    }
  }
  return (
    <>
      <Component title="Create Client">
        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="column"
          >
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Client Name"
                helperText={
                  <span>
                    Client name must be in &apos;camelCasing&apos; and cannot
                    contain spaces or special characters.
                  </span>
                }
                required
                size="small"
                value={name}
                onChange={(e) => handleNameChange(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Display name"
                required
                size="small"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Description"
                rows={4}
                multiline
                style={{ minWidth: '0px' }}
                value={description}
                size="small"
                fullWidth
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Component>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </>
  )
}
