import React, { useEffect, useState } from 'react'

import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableMUI from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import { ComponentDense } from './Component'
import Box from '@mui/material/Box'
import TableSortLabel from '@mui/material/TableSortLabel'
interface TableProps<T> {
  data: T[]
  head?: (string | JSX.Element)[] | undefined
  row: (
    data: T,
    index?: number,
  ) => (JSX.Element | string | number | boolean | undefined)[]
  onRowClick?: (row: T) => void
  sort?: (a: T, b: T, key: keyof T) => number
  size?: 'small' | 'medium'
  count?: number
  page?: number
  pageSize?: number
  onPageChange?: (page: number) => void
  onPageSizeChange?: (pageSize: number) => void
}

type Order = 'asc' | 'desc'

export default function Table<T>({
  data,
  head,
  row,
  onRowClick,
  sort,
  size = 'small',
  count,
  page: defaultPage,
  pageSize: defaultPageSize,
  onPageChange,
  onPageSizeChange,
}: TableProps<T>): JSX.Element {
  const [page, setPage] = useState(defaultPage || 0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize || 10)
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof T | undefined>()
  const handleChangeRowsPerPage = (rowsPer: string) => {
    setRowsPerPage(parseInt(rowsPer, 10))
    setPage(0)
  }

  const handlePageChange = (newPage: number) => {
    onPageChange ? onPageChange(newPage) : setPage(newPage)
  }

  const handlePageSizeChange = (newPage: number) => {
    onPageSizeChange
      ? onPageSizeChange(newPage)
      : handleChangeRowsPerPage(`${newPage}`)
  }

  const handleRequestSort = (by: string) => {
    const key = by as keyof T
    if (!key) {
      return
    }
    const isAsc = orderBy === by && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(key)
  }

  useEffect(() => {
    if (sort && orderBy) {
      const orderMult = order === 'asc' ? 1 : -1
      data.sort((a, b) => orderMult * sort(a, b, orderBy))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, order, orderBy, sort])

  let displayData = data
  if (!defaultPage) {
    displayData = data.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    )
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

  return (
    <Box>
      <div style={{ width: '100%' }}>
        <TableContainer
          component={(props: { [k: string]: unknown }) => (
            <ComponentDense
              {...props}
              width={'100%'}
              height="100"
              overflow={'auto'}
            />
          )}
        >
          <TableMUI size={size}>
            {head && (
              <TableHead>
                <TableRow>
                  {head.map((h, i) => (
                    <TableCell
                      key={`table-head-${i}`}
                      sx={{
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                      }}
                    >
                      {sort ? (
                        <TableSortLabel
                          active={orderBy === h}
                          direction={orderBy === h ? order : 'asc'}
                          onClick={() =>
                            typeof h === 'string' && handleRequestSort(h)
                          }
                        >
                          {h}
                          {orderBy === h ? (
                            <span style={{ display: 'none' }}>
                              {order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        h
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody sx={{ minWidth: 1000 }}>
              {displayData?.map((d, i) => (
                <TableRow
                  key={`table-row-${i}`}
                  onClick={() => onRowClick?.(d)}
                  style={{ cursor: onRowClick ? 'pointer' : 'inherit' }}
                >
                  {row(d).map((r, j) => (
                    <TableCell key={`table-cell-${i}-${j}`}>{r}</TableCell>
                  ))}
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={head?.length || 0} />
                </TableRow>
              )}
            </TableBody>
          </TableMUI>
        </TableContainer>
      </div>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={count || data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, pg) => handlePageChange(pg)}
        onRowsPerPageChange={(e) => {
          console.log(e.target.value)
          handlePageSizeChange(parseInt(e.target.value))
        }}
      />
    </Box>
  )
}
