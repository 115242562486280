import React, { useEffect, useState } from 'react'
import Component from '../common/Component'
import Typography from '@mui/material/Typography'
import { getClients } from '../../api/admin'
import Table from '../common/Table'
import { Client } from '../../api/types'
import { err } from '../../utils/functions'

export default function AllClients(): JSX.Element {
  const [allClients, setAllClients] = useState<Client[]>()

  const head = ['Name']
  const row = (client: Client) => [client.displayName]

  useEffect(() => {
    getClients()
      .then((res) => setAllClients(res?.data || []))
      .catch((e) => err(e))
  }, [])
  return (
    <Component title="All Clients" p={0}>
      {allClients ? (
        <Table data={allClients} head={head} row={row} />
      ) : (
        <Typography>Error fetching all clients.</Typography>
      )}
    </Component>
  )
}
