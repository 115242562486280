import React, { useState } from 'react'
import Component from '../common/Component'
import { addClientService, getServices } from '../../api/service'
import { useEffect } from 'react'
import Alert, { AlertInput, handleAlert } from '../common/Alert'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { err } from '../../utils/functions'

interface AddServiceProps {
  clientId: number
}

export default function AddService({ clientId }: AddServiceProps): JSX.Element {
  const [services, setServices] = useState<string[]>([])
  const [selected, setSelected] = useState('')
  const [fee, setFee] = useState(1)
  const [billing, setBilling] = useState('PayPal Invoice')
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleSubmit = async (
    serviceName: string | undefined,
  ): Promise<void> => {
    if (!serviceName) return

    addClientService(clientId, serviceName, fee, billing)
      .then((res) => {
        handleAlert(setAlert, res, 'Added service to Dashboard.')
      })
      .catch((e) => err(e))
    // TODO add snackbar alerting
  }

  useEffect(() => {
    getServices()
      .then((res) => {
        if (res.success && res.data) {
          const serviceNames = res.data.map((service) => service.name)
          setServices(serviceNames)
        }
      })
      .catch((e) => err(e))
  }, [])

  return (
    <Component title="Add Service">
      <Grid container spacing={2} justifyContent="center" direction="column">
        <Grid item xs={12}>
          Add a service to your dashboard. Once added, you will be able to view,
          configure, and run.
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={selected}
            onChange={(e) => setSelected(e.target.value as string)}
            label="Available Services"
            fullWidth
            variant="outlined"
            select
          >
            <MenuItem value={''}>
              <em>None</em>
            </MenuItem>
            {services.map((service) => (
              <MenuItem key={`available-services-${service}`} value={service}>
                {service}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={billing}
            onChange={(e) => setBilling(e.target.value as string)}
            label="Billing"
            id="billing"
            fullWidth
            select
          >
            {['PayPal Invoice'].map((billing) => (
              <MenuItem key={`billing-method-${billing}`} value={billing}>
                {billing}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            type="number"
            label="Fee"
            required
            size="small"
            defaultValue={fee}
            onChange={(e) => setFee(e.target.value as unknown as number)}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(selected)}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Component>
  )
}
