import React from 'react'
import NavBar from '../components/common/NavBar'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Component from '../components/common/Component'
import Grid from '@mui/material/Grid'

export default function AboutPage(): JSX.Element {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container>
        <div
          style={{
            padding: '1em',
            paddingTop: '2em',
          }}
        >
          <Component>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">What is TeamAutomate?</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Team Automate is an inventory management service that
                  facilitates quick and easy channel listing, syncing, and sales
                  insights.
                  <br />
                  <br />
                  Our software utilizes powerful automation, templating,
                  attributes, customizable integrations, <br />
                  and power features to give users the flexibility to list how
                  they need and access channel features to increase throughput.
                  <br />
                  <br />
                  Our automation makes the inventory creation and listing
                  process seamless.
                </Typography>
              </Grid>
            </Grid>
          </Component>
        </div>
      </Container>
    </div>
  )
}
