import React from 'react'
import Grid from '@mui/material/Grid'
import ClientSelect from '../../components/admin/ClientSelect'

export default function Login(): JSX.Element {
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            marginTop: '3em',
          }}
        >
          <ClientSelect />
        </Grid>
      </Grid>
    </>
  )
}
