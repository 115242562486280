/**
 * Utility Functions
 */

import { AxiosResponse } from 'axios'
import { ROLES } from '../types'
import { APIResult } from './types'
import { ATTRIBUTE_TAG_END, ATTRIBUTE_TAG_START } from './constants'

export async function sha256(message: string): Promise<string> {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // hash the message
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgBuffer)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

export function getResult<T>(res: AxiosResponse | void): APIResult<T> {
  if (res) {
    return res.data as APIResult<T>
  } else {
    return { success: false, data: undefined, message: 'No response.' }
  }
}

export function formatDate(
  date: string | number | Date | null | undefined,
): string {
  return new Date(date || new Date()).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}

export function formatNameValue(str: string | undefined): string {
  if (!str?.length) return ''

  let formattedName = str.replace(/[^a-zA-Z0-9]/g, '')
  formattedName =
    formattedName[0]?.toLocaleLowerCase() +
    formattedName.slice(1, formattedName.length)

  return formattedName
}

export function isSuperAdmin(roleId: number | undefined): boolean {
  return !!roleId && roleId <= ROLES.SUPER_ADMIN
}

export function isAdmin(roleId: number | undefined): boolean {
  return !!roleId && roleId <= ROLES.ADMIN
}

export function isManager(roleId: number | undefined): boolean {
  return !!roleId && roleId <= ROLES.MANAGER
}

export function isMember(roleId: number | undefined): boolean {
  return !!roleId && roleId <= ROLES.MEMBER
}

export function wait(ms = 100): Promise<void> {
  return new Promise((res) => setTimeout(() => res(), ms))
}

/**
 * Split by delimiter and trim each result.
 */
export function split(str: string | undefined, delim = ' '): string[] {
  if (!str) return []
  return str.split(delim).map((s) => s.trim())
}

export function log(msg: unknown): void {
  console.log(msg)
}

export function err(e: Error): void {
  console.error(e.message)
}

export function random(range: number, min = 0): number {
  return Math.floor(Math.random() * range) + min
}

export function range(range: number): number[] {
  return Array(range)
    .fill(0)
    .map((zero, i) => i)
}

export function createSku(): string {
  const bank = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  return range(8)
    .map((i) => bank[random(bank.length)])
    .join('')
}

export function copy<T>(data: T): T {
  return JSON.parse(JSON.stringify(data))
}

export function getAttributeTag(name: string): string {
  if (!name) return ''
  return ATTRIBUTE_TAG_START + ' ' + name + ' ' + ATTRIBUTE_TAG_END
}

export function getAttributeName(tag: string | undefined): string | undefined {
  if (!tag) return undefined
  const pattern = `${ATTRIBUTE_TAG_START}\\s*([a-zA-Z0-9\\-_/ ]+?)\\s*${ATTRIBUTE_TAG_END}`
  const reg = new RegExp(pattern, 'gim')
  return reg.exec(tag)?.[1]
}

export function getAttributeNames(tag: string | undefined): string[] {
  if (!tag) return []
  const pattern = `${ATTRIBUTE_TAG_START}\\s*([a-zA-Z0-9\\-_/ ]+?)\\s*${ATTRIBUTE_TAG_END}`
  const reg = new RegExp(pattern, 'gim')
  const matches = reg.exec(tag) || []
  matches.shift() // remove first entire match
  return matches
}

export function isLike(
  str1: string | undefined,
  str2: string | undefined,
): boolean {
  if (str1 === undefined || str2 === undefined) return false
  const string1 = str1.toLowerCase().trim()
  const string2 = str2.toLowerCase().trim()
  return string1 === string2
}

export function isLikeAny(
  compare: string | undefined,
  strings: string[],
): boolean {
  return strings
    .map((str) => isLike(str, compare))
    .some((alike) => alike === true)
}

export function isDefined<T>(input: T | undefined): input is T {
  return input !== undefined && input !== null
}

export function round(
  num: number,
  digits = 2,
  options: { method: 'round' | 'ceil' | 'floor' } = { method: 'round' },
): number {
  const mult = Math.pow(10, digits)

  switch (options.method) {
    case 'round':
      return Math.round(num * mult) / mult
    case 'ceil':
      return Math.ceil(num * mult) / mult
    case 'floor':
      return Math.floor(num * mult) / mult
    default:
      return Math.round(num * mult) / mult
  }
}

export function unique<T>(array: T[]): T[] {
  return [...new Set(array)]
}

export function parseBoolean(boolString: string | undefined): boolean {
  return boolString?.toLowerCase() === 'true'
}

export function removeElements(array: string[], remove: string[]): string[] {
  const newArray: string[] = []
  array.forEach((a) => {
    if (!remove.includes(a)) {
      newArray.push(a)
    }
  })
  return newArray
}
