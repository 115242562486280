import { ProductTemplateAttributeTypes } from '../api/types'

export const DAY_MS = 86400 * 1000

export const SEC_TO_MS = 1000
export const MS_TO_SEC = 0.001
export const MIN_TO_SEC = 60
export const HR_TO_MIN = 60
export const DAY_TO_HR = 24
export const DAY_TO_SEC = 1000

export const ATTRIBUTE_TAG_START = '{{'
export const ATTRIBUTE_TAG_END = '}}'

export const DEFAULT_ATTRIBUTE_TYPE: ProductTemplateAttributeTypes = 'text'

export const CENTS_TO_DOLLAR = 0.01

export const APP_URL = process.env.APP_URL || 'https://www.teamautomate.com'
