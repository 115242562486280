import * as React from 'react'
import { createTheme, ThemeProvider, ThemeOptions } from '@mui/material/styles'
import { orange } from '@mui/material/colors'
import AppContext, { appStateReducer, defaultAppState } from './AppContext'
import { useReducer } from 'react'
import AppRouter from './AppRouter'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const theme = createTheme({
  status: {
    danger: orange[500],
  },
} as ThemeOptions)

function App(): JSX.Element {
  const [appState, appReducer] = useReducer(appStateReducer, defaultAppState)

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <AppContext.Provider value={{ appState, appReducer }}>
          <ThemeProvider theme={theme}>
            <AppRouter></AppRouter>
          </ThemeProvider>
        </AppContext.Provider>
      </DndProvider>
    </>
  )
}

export default App
