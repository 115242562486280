import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'

export default function ForgotPassword(): JSX.Element {
  const history = useHistory()

  const handleReturn = () => {
    history.push('/login')
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          xs={12}
          md={6}
          lg={3}
          sx={{
            marginTop: '3em',
          }}
        >
          <Paper>
            <Box p={2}>
              <Box p={2}>
                <Typography variant="h5" component="h1">
                  Forgot Password
                </Typography>
              </Box>

              <Box style={{ paddingTop: '1em', paddingBottom: '2em' }}>
                <Typography>
                  Please contact us to resolve account issues.
                </Typography>
              </Box>

              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReturn}
                >
                  Back
                </Button>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
