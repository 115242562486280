import React from 'react'
import NavBar from '../components/common/NavBar'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Component from '../components/common/Component'
export default function SupportPage(): JSX.Element {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container>
        <div
          style={{
            padding: '1em',
            paddingTop: '2em',
          }}
        >
          <Component>
            <Typography>
              We are sorry to hear if you are encountering issues with our app.
            </Typography>
            <Typography>
              If you have any issues, questions, or inquiries, please reach out
              to our support team.
            </Typography>
            <Typography>
              Please email us at{' '}
              <a href="mailto:info@teamautomate.com">info@teamautomate.com</a>{' '}
              and we will be happy to assist you!
            </Typography>
          </Component>
        </div>
      </Container>
    </div>
  )
}
